import styled from 'styled-components';
import {TakeAssessmentButton} from '../../containers/WellnessAssessment/WellbeingDashboard/styles';
const RegularFont = "Rubik-Regular";
const MediumFont = "Rubik-Medium";
const GraphLayout  = styled.div`
  width: 100%;
  padding: 20px 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    padding: 20px 10px;
  }
  @media (max-width: 500px) {
    padding: 5px;
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;
const TextScore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-family: ${MediumFont};
  font-size: 17px;
  color: #282C37;
`;
const ScoreContainer  = styled.div`
  width: 100%;
  padding: 20px 40px;
  margin: 0 auto;
  display: flex;
  background-color: #4798D9;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 600px) {
    padding: 20px 10px;
  }
`;
const ContragsText = styled.div`
  padding: 15px 15px 20px;
  font-family: ${({font}) => font ? `${MediumFont}` : `${RegularFont}`};
  font-size: ${({font}) => font ? '15px' : '17px'};
  color: ${({font}) => font ? '#26262B': '#FFFFFF'};
  text-align: center;
`;
const WBAGraphContainer = styled.div`
  width: 80%;
  background-color: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  > div: last-child{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 30px;
    padding: 10px 15px;
    flex-wrap: wrap;
    @media (max-width: 700px){
      flex-direction: column;  
    }
  }
  @media(max-width: 600px){
    width: 100%;
  }
`;
const GraphDataContainer = styled.div`
  width: 400px;
  margin: 10px auto;
  @media(max-width: 1000px){
    width: 100%;
  }
`;
const Labels = styled.div`
    display: flex;
    align-items: center;
    width: 95px;
    > div{
      width: 10px;
      height: 10px;
      background-color: ${({bgColor}) => bgColor && bgColor};
    }
    > span{
      color: #282C37;
      font-family: ${MediumFont};
      font-size: 14px;
      margin-left: 10px;
      text-transform: capitalize;
    }
  }
`;
const DoneButton = styled(TakeAssessmentButton)`
  width: 350px;
  margin: 20px 0 10px;
  background-color: #FFFFFF;
  color: #4697D8;
  @media (max-width: 500px){
    width: 80%;
  }
`;
export {GraphLayout, ButtonContainer, TextScore, ScoreContainer, ContragsText, WBAGraphContainer, GraphDataContainer, Labels, DoneButton};