import styled from 'styled-components';
import {UserButton} from '../EngagementSection/styles';
import { ImageUrl } from '../../utils/constants';

const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const backgroundUrl = `url(${ImageUrl}/images/HomePageV2/tailored-background.png)`;

const Button = styled(UserButton)`
  float: ${({noneFloat}) => noneFloat && 'none'};
  text-transform: capitalize;
  font-family: ${BoldFont};
  width: 184px;
  fontSize: 13px !important;
  @media(max-width: 1200px) {
    margin-left: 0px;
  }
  @media(max-width: 500px) {
    margin-left: 0px;
  }
`;

const JourneyBox = styled.div`
    background-color: #f7f7f7;
    float: left;
    width: 100%;
    padding: 80px 50px 70px 50px;
    > h1 {
        text-align: center;
        padding-right: 50px;
        font-size: 30px;
        color: rgb(43, 40, 30);
        font-family: ${MediumFont};
        margin: 0px;
        font-weight: 700;
    }
    > h2 {
        text-align: center;
        padding-right: 50px;
        font-size: 30px;
        color: rgb(43, 40, 30);
        font-family: ${MediumFont};
        margin: 0px;
        font-weight: 700;
    }
    .journeyImageContainer {
        width: 80%;
        position: relative;
        img {
           width: 100%;
           max-height: 1850px;
        }
        .amigoContainer {
           top: 8%;
           right: -5%; 
        }
        .strategyContainer {
           top: 25%;
           left: 7%;
        }
        .implementationContainer {
           top: 43%;
           right: -5%;
        }
        .dataContainer {
            top: 63%;
            left: 7%;
        }
        .optimizationContainer {
           top: 85%;
           right: -5%; 
        }        
    }
    @media (max-width: 1200px) {
        .journeyImageContainer {
            width: 85%;
        }
    }
    @media (max-width: 1100px) {
        padding: 80px 90px 70px 100px;
        .journeyImageContainer {
            width: 90%;
        }
    }
    
    @media (max-width: 1000px) {
        padding: 60px 6vw;  
    }
    @media (max-width: 900px) {
        .journeyImageContainer {
            width: 95%;
        }
    }
    @media (max-width: 850px) {
        .journeyImageContainer {
            img {
               width: 90%;
            }
        }   
    }
    @media (max-width: 750px) {
       .journeyImageContainer {
            img {
               width: 85%;
            }
        } 
    }
    @media (max-width: 650px) {
    .journeyImageContainer {
        .amigoContainer {
           top: 6%;
           right: -5%; 
        }
        .strategyContainer {
           top: 23%;
        }
        .implementationContainer {
           top: 43%;
        }
        .dataContainer {
            top: 64%;
        }
        .optimizationContainer {
           top: 83%;
        }
      }
    }
    @media (max-width: 600px) {
       .journeyImageContainer {
            img {
                display: none;
            }
       }
       > h1 {
        padding: 0px;
        margin: 20px 0px;
       }
    }
    @media (max-width: 500px) {
        padding: 50px 10px;
    }
    @media (min-width: 1380px) {
        .journeyImageContainer {
          max-width: 1000px;
          display: inline-block;
          margin-left: 5%;
        }
    }
    @media (min-width: 1600px) {
        padding-left: calc(calc(100% - 1300px) / 2);
        padding-right: calc(calc(100% - 1420px) / 2);
    }
    
    .text-right {
        display: flex;
        width: 50%;
        padding-left: 69px;
        flex-direction: column;
      @media (max-width: 900px) {
        width: 50%;
        margin: 0 auto;
        padding: 0;
      }
      @media (max-width: 479px) {
        width: 80%;
      }
    }
    
    .title {
      display: block;
      padding-top: 76px;
      padding-bottom: 0px;
      justify-content: flex-start;
      font-size: 20px;
      text-align: left;
      font-family: ${BoldFont}
      @media (max-width: 900px) {
        text-align: center;
        margin: 10px auto;
        padding-top: 28px;
      }
      
    }
    .description {
      max-width: 300px;
      color: rgba(64, 69, 85, 0.7);
      font-size: 16px;
      text-align: left;
      @media (max-width: 900px) {
        text-align: center;
        margin: 0 auto;
        padding-bottom: 59px;
      }
    }
    
    .text-left {
      width: auto;
      display: flex;
      padding-top: 59px;
      padding-right: 56px;
      padding-left: 221px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: right;
      @media (max-width: 900px) {
        margin: 0 auto;
        padding: 0;
        text-align: center;
      }
    }
    
    .arrow-image {
      position: absolute;
      width: 25%;
      left: 40%;
      top: 70%;
      
      @media (max-width: 1100px) {
        &.first {
          top: 65%;
        }
        
        &.second {
          width: 30%;
        }
      }
      
      @media (max-width: 900px) {
        display: none;
      }
    }
    
`;

const Abs = styled.div`
    position: absolute;
    max-width: 210px;
    @media (min-width: 600px) and (max-width: 767px) {
      max-width: 176px;
    }
    
    @media (min-width: 320px) and (max-width: 600px) {
      position: relative;
      width: 100%;
      max-width: none;
    }
`;

const IntegrationContainer = styled.div`
    position: relative;
    float: left;
    width: 100%;
    min-height: 450px;
    background: ${backgroundUrl};
    background-size: 100% 100%; 
    padding: 100px 30px;
    h1 {
      font-size: 30px;
    }
    @media (max-width: 1000px) {
        padding: 100px 6vw;  
    }
    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0px;
    }
    @media (max-width: 500px) {
        padding: 0px 10px 50px 10px;
    }
    @media (min-width: 1600px) {
        padding-left: calc(calc(100% - 1540px) / 2);
        padding-right: calc(calc(100% - 1540px) / 2);
    }
`;

const TextContainer = styled.div`
    width: 45%;
    padding: 10px;
    float: left;
    .button-wrapper {
      text-align: left;
      padding-top: 10px;
    }
    @media (max-width: 900px) {
      float: left;
      width: 100%;
      padding: 0px;
    }
`;

const Text = styled.div`
    max-width: 375px;
    padding: 10px;
    margin: 24px auto 0 auto;
    float: right;
    color: #fff;
    font-family: ${RegularFont};
    h1 {
        font-size: 45px;
        font-family: ${RegularFont};
        font-weight: 400;
        margin-bottom: 35px;
        padding-right: 15px;
    }
    h2{
        font-size: 45px;
        font-family: ${RegularFont};
        font-weight: 400;
        margin-bottom: 35px;
        padding-right: 15px;
    }
    p {
        font-size: 18px;
        font-family: ${RegularFont};
        padding-right: 100px;
        font-weight: 400;
        margin-bottom: 15px;
    }
    button{
      fontSize: 13px;
    }
   @media (max-width: 900px) {
     max-width: unset;
     padding: 0px 0px 50px 0px;
     float: left;
     p {
        padding-right: 0px;
     }   
   } 
`;

const IntegrationImageWrapper = styled.div`
    float: left;
    width: 55%;
    padding: 33px 0;
    max-width: 490px;
    margin-left: -40px;
    
    & > img {
        width: 100%;
    }
    
    @media (max-width: 900px) {
        float: left;
        width: 100%;
        max-width: 600px;
        margin-left: 0;
    }
`;

const IntegrationContentContainer = styled.div`
    position: relative;
    float: left;
    width: 100%;
    background-color: #f6f6f8;
    min-height: 400px;
    padding: 30px 30px 80px 30px;
    .tab-component-container .button-wrapper button{
      font-size: 13px ;
    }
    @media (min-width: 1600px) {
        padding-left: calc(calc(100% - 1540px) / 2);
        padding-right: calc(calc(100% - 1540px) / 2);
    }
    
`;

const AlignInitiatives = styled.div`
  display: flex;
  padding-top: 49px;
  padding-bottom: 49px;
  justify-content: space-between;
  position: relative;
  
  @media (max-width: 900px) {
    flex-direction: column;
    margin: 0 auto;
    text-align:center;
    align-items:center;
  }
`;

const CommunicationAutomation = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    
    @media (max-width: 900px) {
    
    flex-direction: column-reverse;
    margin: 0 auto;
    text-align:center;
    align-items:center;
  }
  
    .title {
      padding-top: 0;
      padding-left: 0;
    }
`;

const ImageLeft = styled.div`
      display: inline-block;
      max-width: 60%;
      margin-right: 20px;
      margin-left: 39px;
      padding-right: 0px;
      padding-left: 0px;
      flex: 1;
      text-align: center;
      display: inline-block;
      width: auto;
      height: auto;
      text-align: right;
    
    @media (max-width: 900px) {
      max-width: 80%;
      width: 100%;
      text-align: center;
      margin: 0 auto;
    }
    
    .image-left {
      display: inline-block;
      max-width: 60%;
      margin-right: 20px;
      margin-left: 39px;
      padding-right: 0px;
      padding-left: 0px;
      flex: 1;
      text-align: center;
      display: inline-block;
      width: auto;
      height: auto;
      position: relative;
      z-index: 1;
    }
`;

const ImageRight = styled.div`
    max-width: 50%; 
    .image-left {
      display: inline-block;
      max-width: 60%;
      margin-right: 20px;
      margin-left: 39px;
      padding-right: 0px;
      padding-left: 0px;
      flex: 1;
      text-align: center;
      display: inline-block;
      width: auto;
      height: auto;
      position: relative;
      z-index: 1;
    }
    @media (max-width: 900px) {
      max-width: 80%;
      width: 100%;
      text-align: center;
      margin: 0 auto;
    }
`;

export {
  Button,
  IntegrationContainer,
  TextContainer,
  Text,
  IntegrationImageWrapper,
  IntegrationContentContainer,
  JourneyBox,
  Abs,
  AlignInitiatives,
  CommunicationAutomation,
  ImageLeft,
  ImageRight,
}