import React from 'react';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  width: 100% !important;
  margin-top: 10px;
  height: 100%;
  .highcharts-container {
    width: 100% !important;
    > svg {
      width: 100%;
    }
  }
`;

class WBABarChart extends React.Component {

  componentDidMount() {
    this.renderGraph(this.props);
  }

  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.renderGraph(this.props);
    }
  }

  renderGraph = (props) => {
    Highcharts.setOptions({
      colors: props.colors
    })
    Highcharts.chart(props.id, {
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: props.categories,
        visible: false,
        title: {
          text: null
        },
      },
      yAxis: {
        max: 100,
        title: {
          text: null
        },
      },
      tooltip: {
        enabled: true,
        formatter: function() {
          return this.x + '<br/>' + 'Score:' + this.y+'%';
        }
      },
      legends: {
        enabled: false
      },
      series: [{
        type: 'column',
        colorByPoint: true,
        data: props.data,
        showInLegend: false
      }]
    })
  };

  render(){
    const {id} =  this.props;
    return (
      <Container id={id} />
    )
  }
}

WBABarChart.defaultProps = {
  id: "chart"
}

WBABarChart.propTypes = {
  id: PropTypes.string,
  colors: PropTypes.array,
  data: PropTypes.array,
  categories: PropTypes.array
};

export default WBABarChart;